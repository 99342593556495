import React from "react"
import Layout from "../layouts/Layout"

export default () => (
  <Layout>
    <br />
    <h1>SlipNoteについて</h1>
    <br />
    <h2>はじめに</h2>
    <p>
      本サイト(slipnote.net)では、新しく習得した知識や技術、勉強したときに躓いた事とその解決方法などのメモを公開しています。
    </p>
    <p>
      掲載内容については、一次情報を参考にしています。しかし、間違いであったり、もっとスマートな解決方法もあると思いますので、掲載内容をご利用の前には、必ず公式のドキュメントをご確認の上、ご自身の判断でご利用ください。
    </p>
    <br />
    <h2>運営者について</h2>
    <p>
      秋田県でウェブサイトを中心にディレクション、デザイン、フロントエンド、バックエンド、たまにインフラを楽しんでいます。
    </p>
    <p>
      自分がやりたい事に対して、とても理解のある会社であり、新しい技術を習得しやすい環境と仲間に囲まれて仕事をしています。
    </p>
    <p>(*煮干し食べすぎてお腹いたいといったら、休ませてくれました。)</p>
    <p>
      プライベートでは、子どもが生まれ、こちらも日々楽しく勉強しながら暮らしています。
    </p>
  </Layout>
)
